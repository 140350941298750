import { CloseButton } from '@/app/[lang]/[partner]/(ActiveCustomer)/components/CloseButton';
import { useTranslation } from '@/app/i18n/client';
import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { ReactNode } from 'react';

const Header = ({
  pages,
  navigateUrlOnHeaderClose,
  headerTitle,
}: {
  pages: {
    label: string;
    path: string[];
  }[];
  navigateUrlOnHeaderClose?: string;
  headerTitle?: ReactNode;
}) => {
  const pathname = usePathname();

  const currentStepIndex = pages.findIndex(
    (page) => page.path.indexOf(pathname) !== -1,
  );

  const { t } = useTranslation();

  const router = useRouter();

  const handleExitRenewalsJourney = (navigateUrl: string) => {
    router.push(navigateUrl);
  };

  return (
    <header
      className={classNames(
        'flex',
        'flex-row',
        'flex-grow',
        'w-full',
        'justify-center',
        'px-4',
        'py-k-8',
        'border-core-4',
        'border-b-[1px]',
      )}
    >
      <div
        className={classNames(
          'max-w-k-column',
          'w-full',
          'flex',
          'flex-row',
          'justify-center',
          'gap-[11px]',
          'px-k-12',
        )}
      >
        {headerTitle && <h1 className="text-body-2">{headerTitle}</h1>}

        {currentStepIndex >= 0 &&
          pages.map((page, i) => (
            <div key={JSON.stringify(page)} className="flex-auto basis-0">
              <div
                className={classNames(
                  i <= currentStepIndex ? 'bg-core-8' : 'bg-core-4',
                  'rounded-full',
                  'h-[6px]',
                )}
              />
              <p
                className={classNames(
                  'text-center',
                  i <= currentStepIndex ? 'text-body-5' : 'text-body-5-light',
                  'text-nowrap',
                  'mt-k-7',
                )}
                data-complete={i <= currentStepIndex}
              >
                {page.label}
              </p>
            </div>
          ))}
      </div>
      {navigateUrlOnHeaderClose && (
        <div className="absolute right-k-4 top-k-4 laptop:right-k-6 laptop:top-k-6">
          <CloseButton
            id="ace-renewals-journey-close-button"
            onClick={() => handleExitRenewalsJourney(navigateUrlOnHeaderClose)}
            mixpanelButtonText="ACERenewalsJourneyCloseButton"
            ariaLabel={t('renewal-journey-exit-label-text')}
          />
        </div>
      )}
    </header>
  );
};

export { Header };
