'use client';
import { useAnalytics } from './useAnalytics';

const useUTMParams = () => {
  const analytics = useAnalytics();

  return {
    marketing_campaign: analytics?.getProperty('utm_campaign'),
    marketing_content: analytics?.getProperty('utm_content'),
    marketing_source: analytics?.getProperty('utm_source'),
    marketing_medium: analytics?.getProperty('utm_medium'),
    marketing_term: analytics?.getProperty('utm_term'),
  };
};

export { useUTMParams };
