const routePaths = {
  LANDING: '/',
  APPLICATION: '/application/',
};

const applicationRoutePaths = {
  BUSINESS_DETAILS_PART_ONE_URL: 'business-details/part-one',
  BUSINESS_DETAILS_PART_TWO_URL: 'business-details/part-two',
  BUSINESS_DETAILS_PART_THREE_URL: 'business-details/part-three',
  OWNERSHIP_DETAILS_URL: 'ownership-details',
  OWNERSHIP_DETAILS_PARTNER_URL: 'ownership-details/partner',
  OWNERSHIP_DETAILS_LTD_URL: 'ownership-details/ltd',
  ADD_SHAREHOLDER_LTD_URL: 'ownership-details/ltd/shareholder',
  CONTRACTS_URL: 'contracts',
  YOUR_QUOTE_URL: 'your-quote',
  REVIEW: 'review',
  CONNECT_YOUR_ACCOUNT: 'connect-your-account',
  APPLICATION_SUBMITTED: 'finished',
  REVIEW_TERMS: 'review-terms',
  ERROR: 'error',
};

const businessDetailsPartOneUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL}`;
const businessDetailsPartTwoUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL}`;
const businessDetailsPartThreeUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.BUSINESS_DETAILS_PART_THREE_URL}`;
const ownershipDetailsUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.OWNERSHIP_DETAILS_URL}`;
const ownershipDetailsPartnerUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.OWNERSHIP_DETAILS_PARTNER_URL}`;
const ownershipDetailsLtdUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.OWNERSHIP_DETAILS_LTD_URL}`;
const ownershipDetailsLtdAddShareHolderUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.ADD_SHAREHOLDER_LTD_URL}`;
const yourQuoteUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.YOUR_QUOTE_URL}`;
const reviewUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.REVIEW}`;
const applicationSubmittedUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.APPLICATION_SUBMITTED}`;
const contractsUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.CONTRACTS_URL}`;
const reviewTermsUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.REVIEW_TERMS}`;
const connectAccountUrl: string = `${routePaths.APPLICATION}${applicationRoutePaths.CONNECT_YOUR_ACCOUNT}`;
const errorPage: string = `${routePaths.APPLICATION}${applicationRoutePaths.ERROR}`;

const applicationStartingPoint = businessDetailsPartOneUrl;

const estimatedCompletiontimes: { [key: string]: number } = {
  [businessDetailsPartOneUrl]: 4,
  [businessDetailsPartTwoUrl]: 4,
  [businessDetailsPartThreeUrl]: 3,
  [ownershipDetailsUrl]: 3,
  [ownershipDetailsLtdUrl]: 3,
  [ownershipDetailsLtdAddShareHolderUrl]: 3,
  [ownershipDetailsPartnerUrl]: 3,
  [yourQuoteUrl]: 2,
  [contractsUrl]: 1,
  [reviewTermsUrl]: 1,
  [connectAccountUrl]: 1,
};

enum Route {
  DASHBOARD = '/dashboard',
  HELP_CENTER = '/help-center',
  DETAILS = '/details',
  PAYMENT_HISTORY = '/payment-history',
  RENEWAL_START = '/renewal/start',
  RENEWAL_QUOTE = '/renewal/quote',
  RENEWAL_CONFIRM = '/renewal/confirm',
  RENEWAL_REVIEW = '/renewal/review',
  RENEWAL_COMPLETE = '/renewal/complete',
  RENEWAL_SIGN_CONTRACT = '/renewal/sign-contract',
}

export {
  routePaths,
  applicationRoutePaths,
  businessDetailsPartOneUrl,
  businessDetailsPartTwoUrl,
  businessDetailsPartThreeUrl,
  ownershipDetailsUrl,
  ownershipDetailsPartnerUrl,
  ownershipDetailsLtdUrl,
  ownershipDetailsLtdAddShareHolderUrl,
  yourQuoteUrl,
  reviewUrl,
  applicationSubmittedUrl,
  contractsUrl,
  reviewTermsUrl,
  connectAccountUrl,
  errorPage,
  applicationStartingPoint,
  estimatedCompletiontimes,
  Route,
};
