'use client'; //StepTracker component only works on client
import { ReactNode } from 'react';
import { StepTracker } from '../StepTracker';
import Footer from '@/app/components/Footer/Footer';
import { Sidebar } from '@/app/[lang]/[partner]/(ActiveCustomer)/components/Sidebar';
import { Header } from '../Header/Header';
import classNames from 'classnames';

type PageContainerProps = {
  children: ReactNode;
  pageTitle?: ReactNode;
  partner: string;
  name: string;
  footerDisclaimer?: string;
  sidebar?: boolean;
  header?: { label: string; path: string[] }[];
  variant?: 'kameleon' | 'default';
  navigateUrlOnHeaderClose?: string;
  headerTitle?: ReactNode;
  wideLayout?: boolean;
};

const PageContainer = ({
  children,
  pageTitle,
  partner,
  name,
  footerDisclaimer,
  sidebar = true,
  header,
  variant = 'default',
  navigateUrlOnHeaderClose,
  headerTitle,
  wideLayout = false,
}: PageContainerProps) => {
  return (
    <div
      className={classNames('laptop:flex', 'laptop:flex-row', {
        'flex-col': variant === 'kameleon',
        'laptop:flex-col': variant === 'kameleon',
      })}
    >
      {sidebar && <Sidebar />}
      <StepTracker partner={partner} stepName={name} />
      {header && (
        <Header
          pages={header}
          navigateUrlOnHeaderClose={navigateUrlOnHeaderClose}
          headerTitle={headerTitle}
        />
      )}
      <div className={classNames('flex', 'flex-col')}>
        <main>
          <div className="flex flex-col min-h-screen">
            {pageTitle}
            <div
              className={classNames(
                variant === 'default'
                  ? ['max-w-5xl', 'px-k-6', 'tablet:px-k-11', 'flex-1']
                  : [
                      'w-full',
                      'margin-auto',
                      'flex-1',
                      'flex-row',
                      'self-center',
                      'px-k-6',
                      {
                        'max-w-k-column': !wideLayout,
                        'max-w-k-column-lg': wideLayout,
                      },
                    ],
              )}
            >
              {children}
            </div>
          </div>
        </main>
        <Footer disclaimer={footerDisclaimer} partner={partner} />
      </div>
    </div>
  );
};

export { PageContainer };
