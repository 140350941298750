'use client';

import { businessTypeValues } from '@/lib/businessTypeRoutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { DeepPartial } from '@/lib/types/DeepPartial.types';
import {
  Applicant,
  Application,
  Company,
  Consents,
  OffersPayload,
} from '@/lib/types/OffersPayload.types';
import dayjs from 'dayjs';
import {
  BusinessFormDetails,
  OwnershipFormDetails,
  QuoteFormDetails,
  ReviewFormDetails,
} from './actions';

interface Inputs
  extends BusinessFormDetails,
    OwnershipFormDetails,
    QuoteFormDetails,
    ReviewFormDetails {}

const offersPayloadMapper = (
  data: any, //<Inputs>,
  partner: string,
  existing?: DeepPartial<OffersPayload>,
): DeepPartial<OffersPayload> => {
  const partnerSettings: any = getPartnerSettings(partner);

  const application = applicationMapper(
    data,
    partnerSettings,
    existing?.application,
  );
  const consents = consentMapper(data, existing?.consents);
  const company = companyMapper(
    data,
    partnerSettings,
    existing?.company,
    existing?.application?.merchant_id,
  );

  let applicants: DeepPartial<Applicant[]> = existing?.applicants ?? [];
  let index = data.partnerIndex ?? 0;

  const isPrimary = index === 0;

  applicants[index] = applicantMapper(
    data,
    partnerSettings,
    isPrimary,
    existing?.applicants?.[index],
  );

  const offersPayload = {
    application,
    consents,
    company,
    applicants,
  };

  return offersPayload;
};

const applicationMapper = (
  data: Partial<Inputs>,
  partnerSettings: any,
  application?: Application,
): Application => {
  const newState: Application = {
    merchant_id: data?.merchantID?.trim() ?? application?.merchant_id ?? null,
    currency: partnerSettings?.currency,
    intended_use_of_funds:
      data?.intendedUseOfFunds ?? application?.intended_use_of_funds,
    override_locale_key:
      data.overrideLocaleKey ?? application?.override_locale_key ?? undefined,
  };

  if (data?.amountRequested) {
    newState.amount_requested = data.amountRequested;
  }

  return newState;
};

const consentMapper = (
  data: Partial<Inputs>,
  consents?: Partial<Consents>,
): Partial<Consents> => {
  const newState = {
    bureau_search: true,
    application_comms:
      data.applicationComms ?? consents?.application_comms ?? false,
  };

  return newState;
};

const companyMapper = (
  data: Partial<Inputs>,
  partnerSettings: any,
  company?: DeepPartial<Company>,
  merchantID?: string | null,
): DeepPartial<Company> => {
  const formatedBusinessStartDate =
    data.businessStartDate &&
    dayjs(data.businessStartDate, partnerSettings.dateFormatMonthYear)
      .hour(12)
      .format()
      .toString();

  let payment_channels: any;
  if (partnerSettings?.paymentChannels) {
    payment_channels = [
      { ...partnerSettings?.paymentChannels[0], reference: merchantID?.trim() },
    ];
  }

  const newState: any = {
    legal_name: data.businessName?.trim() ?? company?.legal_name ?? '',
    business_type:
      data.businessType ??
      data?.company?.business_type ??
      company?.business_type ??
      businessTypeValues.sole_trader,
    business_start_date:
      formatedBusinessStartDate ?? company?.business_start_date ?? '',
    partner_start_date:
      formatedBusinessStartDate ?? company?.business_start_date ?? '',
    industry: data.industry ?? company?.industry ?? undefined,
    registered_address: {
      line1:
        data.businessAddress?.line1?.trim() ??
        company?.registered_address?.line1 ??
        '',
      town_city:
        data.businessAddress?.townCity?.trim() ??
        company?.registered_address?.town_city ??
        '',
      postcode:
        data.businessAddress?.postcode?.trim() ??
        company?.registered_address?.postcode ??
        '',
      country:
        data.businessAddress?.country?.trim() ??
        company?.registered_address?.country ??
        (partnerSettings.country === 'GB'
          ? 'United Kingdom'
          : partnerSettings.countryName) ??
        'United Kingdom',
    },
    payment_channels: payment_channels,
    registration_number:
      data.registrationNumber || company?.registration_number || undefined,
  };

  // add optional properties - stop empty strings
  if (
    (data?.businessAddress?.line2 &&
      data?.businessAddress?.line2?.length > 0) ||
    company?.registered_address?.line2
  ) {
    newState.registered_address.line2 =
      data.businessAddress?.line2?.trim() ?? company?.registered_address?.line2;
  }

  if (
    (data?.businessAddress?.state &&
      data?.businessAddress?.state?.length > 0) ||
    company?.registered_address?.state
  ) {
    newState.registered_address.state =
      data.businessAddress?.state?.trim() ?? company?.registered_address?.state;
  }

  if (data?.monthlyRevenues || company?.monthly_revenues) {
    newState.monthly_revenues =
      data?.monthlyRevenues || company?.monthly_revenues;
  }

  return newState;
};

const applicantMapper = (
  data: Partial<Inputs>,
  partnerSettings: any,
  isPrimary: boolean,
  existingApplicant?: DeepPartial<Applicant>,
): Applicant => {
  const formatedDOB =
    data.dob &&
    dayjs(data.dob, partnerSettings.dateFormat).hour(12).format().toString();

  const formatedDateMovedIn =
    data.movedInDate &&
    dayjs(data.movedInDate, partnerSettings.dateFormatMonthYear)
      .hour(12)
      .format()
      .toString();

  const applicant: Applicant = {
    primary: isPrimary,
    first_name: data.firstName?.trim() ?? existingApplicant?.first_name ?? '',
    last_name: data.surname?.trim() ?? existingApplicant?.last_name ?? '',
    email_address:
      data.emailAddress?.trim() ?? existingApplicant?.email_address ?? '',
    telephone_number:
      data.phoneNumber ?? existingApplicant?.telephone_number ?? '',
    date_of_birth: formatedDOB ?? existingApplicant?.date_of_birth ?? '',
    ownership_percentage:
      data.ownershipPercentage ?? existingApplicant?.ownership_percentage,
    residences: [
      {
        address: {
          line1:
            data.homeAddressLineOne?.trim() ??
            existingApplicant?.residences?.[0]?.address?.line1 ??
            '',
          town_city:
            data.city?.trim() ??
            existingApplicant?.residences?.[0]?.address?.town_city ??
            '',
          postcode:
            data.postcode ??
            existingApplicant?.residences?.[0]?.address?.postcode ??
            '',
          country:
            data.businessAddress?.country?.trim() ??
            existingApplicant?.residences?.[0]?.address?.country ??
            (partnerSettings.country === 'GB'
              ? 'United Kingdom'
              : partnerSettings.countryName) ??
            'United Kingdom',
        },
        moved_in_date:
          formatedDateMovedIn ??
          existingApplicant?.residences?.[0]?.moved_in_date ??
          '',
        residential_status:
          data.residentialStatus ??
          existingApplicant?.residences?.[0]?.residential_status ??
          undefined,
        primary: true,
      },
    ],
    identification_number:
      data.identificationNumber ?? existingApplicant?.identification_number,
  };

  // add optional properties - stop empty strings
  if (
    (data?.homeAddressLineTwo && data?.homeAddressLineTwo.length > 0) ||
    existingApplicant?.residences?.[0]?.address!.line2
  ) {
    if (applicant?.residences?.[0]) {
      applicant.residences[0].address!.line2 =
        data.homeAddressLineTwo?.trim() ??
        existingApplicant?.residences?.[0]?.address!.line2;
    }
  }

  if (
    (data?.state && data?.state.length > 0) ||
    existingApplicant?.residences?.[0]?.address!.state
  ) {
    if (applicant?.residences?.[0]) {
      applicant.residences[0].address!.state =
        data.state?.trim() ??
        existingApplicant?.residences?.[0]?.address!.state;
    }
  }

  return applicant;
};

export { offersPayloadMapper };
